<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <card class="form-horizontal">
          <!-- Contact Start -->
            <h4 >Contact Details</h4>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Contact Name</label>
                <div class="col-sm-5">
                  <fg-input type="text" name="contact" v-validate="modelValidations.contact" :error="getError('contact')" v-model="model.contact"></fg-input>
                </div>
              </div>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Email</label>
                <div class="col-sm-5">
                  <fg-input type="text" name="email" v-validate="modelValidations.email" :error="getError('email')" v-model="model.email"></fg-input>
                </div>
              </div>

              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Telephone</label>
                <div class="col-sm-5">
                  <fg-input type="text" name="tel" v-validate="modelValidations.tel" :error="getError('tel')" v-model="model.tel"></fg-input>
                </div>
              </div>
              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Fax</label>
                <div class="col-sm-5">
                  <fg-input type="text" name="fax" v-validate="modelValidations.fax" :error="getError('fax')" v-model="model.fax"></fg-input>
                </div>
              </div>
              <div class="row fatrow">
                <label class="col-sm-2 col-form-label">Mobile / Cell</label>
                <div class="col-sm-5">
                  <fg-input type="text" name="mobile" v-validate="modelValidations.mobile" :error="getError('mobile')" v-model="model.mobile"></fg-input>
                </div>
              </div>

              <div class="row fatrow">
                <div class="col-md-9">
                  <p-button type="info" @click.prevent="validate">
                    Save
                  </p-button>
                </div>
              </div>
              <!-- Contact End -->
        </card>
      </div>
    </div>
  </div>
</template>

<script>
  import * as CONSTS from '../../../../../consts/constants'
  import { Tabs, TabPane, Card } from 'src/components/UIComponents'
  import swal from 'sweetalert2'
  import { mapGetters } from 'vuex'
  import {DatePicker, TimeSelect, Slider, Tag, Input, Button, Select, Option } from 'element-ui'
  import Notes from 'src/components/Maketime/Sales/Notes.vue'

  // create a dictionary for localised labels in forms
  const dictionary = {
    en: {
      attributes: {

        contact: 'contact name',
        tel: 'telephone',
        fax: 'fax',
        email: 'email',
        mobile: 'mobile/cell'
      }
    }
  }


  export default {
    computed: {
      ...mapGetters(['currentUser','userSelectedProductionCcy']),

      isDisabled() {
        // evaluate whatever you need to determine disabled here...
        return this.mode == CONSTS.UPDATE ? true : false
        // return ;
      }
    },

    data() {
      return {
        mode: CONSTS.UPDATE,
        id: '',
        model: {

          // contact
          contact: '',
          tel: '',
          fax: '',
          mobile:'',
          email:'',
          cient_id: 0,
          guid: '',
          company_name: '',
          sales_contact_id: 0
          //

        },
        modelValidations: {

          contact: {
            required: true,
            max: 80
          },
          tel : {
            max: 30
          },
          fax : {
            max: 30
          },
          mobile : {
            max: 30
          },
          email : {
            max: 80,
            email: true
          },
          client_id : {
            required: true,
            numeric: true,
            min_value: 0
          },
          guid: {
            required: true,
            max: 36
          }

        }
      }
    },
    created() {


      if ( this.$route.params.id === 0 || this.$route.params.id === undefined){
        this.mode = CONSTS.NEW
        this.id = null
        this.model.client_id = this.$route.params.client_id
        this.model.company_name = this.$route.params.company_name
      } else {
        this.id = this.$route.params.id
        this.mode = CONSTS.UPDATE
      }

      // set up a dictionary for the EN form labels
      this.$validator.localize(dictionary)
    },
    mounted () {

      // main record

      if ( this.mode == CONSTS.UPDATE){
        this.$http.get( '/sales/client/contact/' + this.id )
          .then(response => {

            if (response.data.hasOwnProperty("apiresponse") && response.data.apiresponse.status !== 'success'){
              this.$notify({
              message: 'There was a problem loading the booking. Please try again. [1]',
              type: 'warning'
            })
            } else {
              this.model = response.data
            }
          })
          .catch(error => {
            // console.log(error)
            this.$notify({
              message: 'There was a problem loading the production. Please try again. [2]',
              type: 'warning'
            })
          })
      }
    },
    components: {
      Notes,
      Card,
      TabPane,
      Tabs,
      [DatePicker.name]: DatePicker,
      [TimeSelect.name]: TimeSelect,
      [Slider.name]: Slider,
      [Tag.name]: Tag,
      [Input.name]: Input,
      [Button.name]: Button,
      [Option.name]: Option,
      [Select.name]: Select
    },
    methods: {
      getError(fieldName) {
        return this.errors.first(fieldName)
      },

      updateContact(){

          let vm = this

          this.$http.put( '/sales/client/contact/' + this.id, this.model )
            .then(function (response) {

              if (response.data.apiresponse.status !== 'success'){
                vm.$notify({
                  message: 'There was a problem saving the contact. Please try again. Error code: ' + response.data.apiresponse.code,
                  type: 'warning'
                })
              } else {
                vm.$notify({
                  message: 'Contact saved',
                  type: 'success'
                })
              }
              })
            .catch(function (error) {
              console.log(error)
              vm.$notify({
                message: 'There was a problem saving the contact. Please try again.',
                type: 'warning'
              })
            })
      },

      saveContact(){
          let vm = this

          this.$http.post( '/sales/client/contact', this.model )
            .then(function (response) {


              // if(this.mode == CONSTS.UPDATE)

              if (response.data.hasOwnProperty("apiresponse") && response.data.apiresponse.status !== 'success'){

                vm.$notify({
                  message: 'There was a problem saving the client. Please try again. Error code: ' + response.data.apiresponse.code,
                  type: 'warning'
                })
              } else {

                // console.log(response.data.apiresponse.keys.guid)
                vm.id = response.data.apiresponse.keys.guid
                vm.model.guid = response.data.apiresponse.keys.guid
                vm.model.sales_contact_id = response.data.apiresponse.keys.sales_contact_id
                vm.mode = CONSTS.UPDATE

                vm.$notify({
                  message: 'Contact saved',
                  type: 'success'
                })
              }
              })
            .catch(function (error) {
              console.log(error)
              vm.$notify({
                message: 'There was a problem saving the Client. Please try again.',
                type: 'warning'
              })
            })
      },
      validate() {
        this.$validator.validateAll().then(isValid => {

          if (isValid){
            if(this.mode == CONSTS.UPDATE){
              this.updateContact()
            } else {
              this.saveContact()
            }
          } else {
            this.$notify({
              message: 'Please correct the validation issues and resubmit.',
              type: 'warning'
            })
          }
        })
      },

      openClient(){
        this.$router.push({ name: 'Client', params: { id: this.model.client_guid }})
      }
    }
  }
</script>
<style scoped>

.el-select {
  display: block;
}

/*   div.row { height: 60px; } */

  div.fatrow { height: 60px; }

  div.superfatrow { height: 100px;}

  .divider div {
    height: 25px;
  }

  .right { text-align: right; }

</style>

