<template>
  <div class="row">
    <div class="col-sm-12 mt-2">
      <div>
        <vue-good-table
          :theme="tableTheme"

          ref="productionNotesGrid"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"

          :columns="columns"
          :rows="rows"
          :pagination-options="{
            enabled: true,
            mode: 'records',

            perPage: serverParams.perPage,
            setCurrentPage: serverParams.page,

            position: 'bottom',
            perPageDropdown: [10, 30, 50],
            dropdownAllowAll: true,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page', // for 'pages' mode
            allLabel: 'All',
            }"
          :sort-options="{
            enabled: true,
            initialSortBy: serverParams.sort
            }">
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'actions'">
                <p-button type="success" size="sm" icon @click="handleEdit(props.$index, props.row)">
                  <i class="fa fa-edit"></i>
                </p-button>
              </span>
              <span v-else>
                {{props.formattedRow[props.column.field]}}
              </span>
            </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { mapGetters } from 'vuex'
  import * as CONSTS from '../../../consts/constants'

  export default{
    components: {

    },
    computed: {
      ...mapGetters(['userSelectedProduction', 'gridStates', 'tableTheme']),
      productionSelected () {
        return this.$store.getters.userSelectedProduction
      }
    },
    watch: {
      productionSelected (userSelectedProduction, oldValue) {
        this.loadData()
      },
      refreshData: function () {
        this.loadData()
      }
    },
    props: ['id', 'mode','refreshData'],
    mounted () {
      this.updateTableFilters()
    },
    created () {
      let gridState = this.gridStates[this.gridName]

      if (gridState){
        this.serverParams = gridState
      }
      this.serverParams.gridName = this.gridName
      this.loadData()
    },
    data () {
      return {
        // gridName: 'productionNotesGrid',
        serverParams: {
          gridName: 'productionNotesGrid',
          columnFilters: {
          },
          sort: {
            field: 'ad_event_date', type: 'desc'
          },
          page: 1,
          perPage: 10
        },
        columns: [
          {
            field: 'company_name',
            label: 'Client',
            tdClass: 'tablecell',
            thClass: 'tablecell',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter client',
            }
          },
          {
            field: 'production_code',
            label: 'Production Code',
            tdClass: 'tablecell',
            thClass: 'tablecell',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
              // filterValue: 'Mil'
            }
          },
          {
            field: 'user_name',
            label: 'User',
            tdClass: 'tablecell',
            thClass: 'tablecell',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter'
            }
          },
          {
            field: 'ad_event_notes',
            label: 'Notes',
            tdClass: 'tablecell',
            thClass: 'tablecell',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter'
            }
          },
          {
            field: 'ad_event_date',
            label: 'Note Date',
            formatFn: this.formatDate,
            tdClass: 'tablecell',
            thClass: 'tablecell',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter'
            }
          },
          {
            field: 'action_date',
            label: 'Action Date',
            formatFn: this.formatDate,
            tdClass: 'tablecell',
            thClass: 'tablecell',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter'
            }
          },
        {
          label: 'Actions',
          field: 'actions',
        }
        ],
        rows: []
      }
    },
    methods: {
      //
      updateTableFilters() {

        for (let i = 0; i < this.columns.length; i++) {
          const col = this.columns[i];
          if (typeof col.filterOptions !== 'undefined') {

            let filterVal = {}

            if ( (filterVal = this.serverParams.columnFilters[ this.columns[i].field ] ) ) {

              let filter = this.columns[i].filterOptions
              filter.filterValue = filterVal
              // seems bonkers having to do this
              let  filterString = JSON.stringify(filter)
              let filterObj = JSON.parse(filterString)

              this.$set(this.columns[i], 'filterOptions', filterObj )
            }
          }
        }
      },
      resetTable: function() {
        this.$refs['productionNotesGrid'].reset()
        this.serverParams.columnFilters = []
        this.$store.dispatch('setGridState', this.serverParams )
      },

      // table events
      onPageChange(params) {
        this.serverParams.page = params.currentPage
        this.$store.dispatch('setGridState', this.serverParams )
      },

      onPerPageChange(params) {
        this.serverParams.perPage = params.currentPerPage
        this.$store.dispatch('setGridState', this.serverParams )
      },

      onSortChange(params) {
        if (params.columnIndex){
        
          let newSort = {
            type: params.sortType,
            field: this.columns[params.columnIndex].field,
          }

          this.serverParams.sort = newSort
          this.$store.dispatch('setGridState', this.serverParams )
        }
      },

      onColumnFilter(params) {
        this.serverParams.columnFilters = params.columnFilters
        this.$store.dispatch('setGridState', this.serverParams )
      },

      // end table events
       searchArray: function(array, key, prop){
          // Optional, but fallback to key['name'] if not selected
          prop = (typeof prop === 'undefined') ? 'name' : prop;

          for (var i=0; i < array.length; i++) {
              if (array[i][prop] === key) {
                  return array[i];
              }
          }
          return null
      },

      formatDate (value) {
        if (value){
          return this.$moment(value).format('DD-MM-YYYY')
        }
      },
      loadData: function () {

        if (this.id === null) return

        // run this component in different modes - booking / client / prod
        let url = ''

        if (this.mode === 'booking'){
          // booking notes
          url = 'sales/booking/' + this.id + '/note'
        } else if (this.mode === 'production'){
          // production notes
          url = '/production/' + this.id + '/note'
        } else if (this.mode === 'client'){
          // client notes
          url = '/sales/client/' + this.id + '/note'
        }


        // console.log('Notes load url:', url, this.mode)

        this.$http.get( url )
        .then(response => ( this.rows = response.data ))
        .catch(error => {
          // console.log(error)
          this.$notify({
            message: 'There was a problem loading the notes. Please try again.',
            type: 'warning'
          })
        })
      },
      handleEdit (index, row) {
           console.log('Notes routing to ', row.guid)

          try {
          this.$router.push({ name: 'Note', params: { id: row.guid }})
          }
          catch(e){
            console.log(e)
          }
      }
    }
  }
</script>
<style lang="scss">

 .tablecell {
   font-size: 14px;
 }

</style>
