var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 mt-2"},[_c('div',[_c('vue-good-table',{ref:"productionNotesGrid",attrs:{"theme":_vm.tableTheme,"columns":_vm.columns,"rows":_vm.rows,"pagination-options":{
          enabled: true,
          mode: 'records',

          perPage: _vm.serverParams.perPage,
          setCurrentPage: _vm.serverParams.page,

          position: 'bottom',
          perPageDropdown: [10, 30, 50],
          dropdownAllowAll: true,
          nextLabel: 'next',
          prevLabel: 'prev',
          rowsPerPageLabel: 'Rows per page',
          ofLabel: 'of',
          pageLabel: 'page', // for 'pages' mode
          allLabel: 'All',
          },"sort-options":{
          enabled: true,
          initialSortBy: _vm.serverParams.sort
          }},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[_c('p-button',{attrs:{"type":"success","size":"sm","icon":""},on:{"click":function($event){return _vm.handleEdit(props.$index, props.row)}}},[_c('i',{staticClass:"fa fa-edit"})])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }